import React, { FC } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import { ManualEnquiry } from '../model/ManualEnquiry';
import { getManualOverrides } from '../service/dao/EnquiryDao';
import {
  compareApplicationId,
  compareName,
  compareUpdated,
} from '../service/enquiryService';
import LinkButton from '../component/LinkButton';
import EnquiryTable, { filterColumn } from '../component/EnquiryTable/EnquiryTable';

const ManualTable: FC<{ accessToken: string }> = ({ accessToken}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('manual.table.appid'),
      dataIndex: 'applicationId',
      render: (id: string, enquiry: ManualEnquiry) => (<LinkButton text={id} url={enquiry.applicationUrl} maxWidth={180} />),
      sorter: { compare: compareApplicationId },
      ...filterColumn('Application ID', 'applicationId'),
    },
    {
      title: t('manual.table.name'),
      dataIndex: 'name',
      render: (_: any, enquiry: ManualEnquiry) => `${enquiry.firstName} ${enquiry.surname}`,
      sorter: { compare: compareName },
      ...filterColumn('Name', 'name'),
    },
    {
      title: t('manual.table.last.updated'),
      dataIndex: 'bookingStatusUpdated',
      render: (date: Date) => (date.toLocaleDateString()),
      sorter: { compare: compareUpdated },
    },
    {
      title: '',
      dataIndex: 'enquiryId',
      render: (id: string, enquiry: ManualEnquiry) => (
        <LinkButton
          title={enquiry.enquiryId}
          text={t('review.table.enquiry.history')}
          url={`${enquiry.engineUrl}${enquiry.enquiryId}`}
          maxWidth={320}
        />
      ),
    },
  ];

  return (
    <EnquiryTable columns={columns} getEnquiries={getManualOverrides} expandable accessToken={accessToken} />
  );
};

export default withAuthenticationRequired(ManualTable, {
});
