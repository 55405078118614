import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import YesNoDialog from '../../component/YesNoDialog';

import { Enquiry } from '../../model/enquiry';

import './ConfirmBookingDialog.less';

//
// Confirm the creation of a medical booking
//

const ConfirmBookingDialog: FC<{
  onExit: (response: boolean) => void,
  open: boolean,
  enquiry: Enquiry | null
}> = ({
  onExit, open, enquiry,
}) => {
  const { t } = useTranslation();

  return (
    <YesNoDialog title={t('confirm.booking.dialog.title')} onExit={onExit} open={open}>
      {t('confirm.booking.dialog.text')}
      <table className="confirm-booking-dialog-booking-table">
        <tbody>
          <tr>
            <th>{t('confirm.booking.dialog.name')}</th>
            <td>{`${enquiry?.firstName} ${enquiry?.surname}`}</td>
          </tr>
          <tr>
            <th>{t('confirm.booking.dialog.evidence.provider')}</th>
            <td>{enquiry?.evidenceProvider}</td>
          </tr>
        </tbody>
      </table>
    </YesNoDialog>
  );
};

export default ConfirmBookingDialog;
