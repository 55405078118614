import React, { FC, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

import { Enquiry } from '../model/enquiry';
import { getOverrides } from '../service/dao/EnquiryDao';
import {
  compareApplicationId, compareName, compareUpdated, compareEvidenceRequested, compareEvidenceProvider,
} from '../service/enquiryService';
import LinkButton from '../component/LinkButton';
import EnquiryTable, { filterColumn, enquiryLinks } from '../component/EnquiryTable/EnquiryTable';

import './ReviewTable.less';

const ReviewTable: FC<{ accessToken: string }> = ({ accessToken }) => {
  const [busy, setBusy] = useState<boolean>(false);
  const { t } = useTranslation();

  const columns = [
    {
      title: t('review.table.appid'),
      dataIndex: 'applicationId',
      render: (id: string, enquiry: Enquiry) => (<LinkButton text={id} url={enquiry.applicationUrl} maxWidth={180} />),
      sorter: { compare: compareApplicationId },
      ...filterColumn('Application ID', 'applicationId'),
    },
    {
      title: t('review.table.name'),
      dataIndex: 'name',
      render: (_: any, enquiry: Enquiry) => `${enquiry.firstName} ${enquiry.surname}`,
      sorter: { compare: compareName },
      ...filterColumn('Name', 'name'),
    },
    {
      title: t('review.table.last.updated'),
      dataIndex: 'bookingStatusUpdated',
      render: (date: Date) => (date.toLocaleDateString()),
      sorter: { compare: compareUpdated },
    },
    {
      title: t('review.table.evidence.requested'),
      dataIndex: 'evidenceRequested',
      sorter: { compare: compareEvidenceRequested },
    },
    {
      title: t('review.table.evidence.provider'),
      dataIndex: 'evidenceProvider',
      sorter: { compare: compareEvidenceProvider },
    },
    {
      title: '',
      dataIndex: 'enquiryId',
      render: (id: string, enquiry: Enquiry) => enquiryLinks(accessToken, enquiry, t, 'review.table.enquiry.', setBusy),
    },
  ];

  return (
    <>
      <EnquiryTable columns={columns} getEnquiries={getOverrides} expandable accessToken={accessToken} />
      <div className="spinner">
        <Spin spinning={busy} />
      </div>
    </>
)
  ;
};

export default withAuthenticationRequired(ReviewTable, {
});
