import { Enquiry } from '../model/enquiry';
import { ManualEnquiry } from '../model/ManualEnquiry';

/**
* Utility functions for handling Enquiry details
*/

//
// Sorting methods - compare the values of Enquiry/ManualEnquiry fields
//

export const compareApplicationId = (
  a: Enquiry | ManualEnquiry,
  b: Enquiry | ManualEnquiry,
): number => a.applicationId.localeCompare(b.applicationId);

export const compareEnquiryId = (
  a: Enquiry | ManualEnquiry,
  b: Enquiry | ManualEnquiry,
): number => a.enquiryId.localeCompare(b.enquiryId);

export const compareName = (
  a: Enquiry | ManualEnquiry,
  b: Enquiry | ManualEnquiry,
): number => {
  const result = a.surname.localeCompare(b.surname);
  if (result === 0) {
    return a.firstName.localeCompare(b.firstName);
  }
  return result;
};

export const compareUpdated = (
  a: Enquiry | ManualEnquiry,
  b: Enquiry | ManualEnquiry,
): number => a.bookingStatusUpdated.getTime() - b.bookingStatusUpdated.getTime();

export const compareEvidenceRequested = (
  a: Enquiry | ManualEnquiry,
  b: Enquiry | ManualEnquiry,
): number => a.evidenceRequested.localeCompare(b.evidenceRequested);

export const compareEvidenceProvider = (
  a: Enquiry,
  b: Enquiry,
): number => a.evidenceProvider.localeCompare(b.evidenceProvider);

export const compareBookingStatus = (
  a: Enquiry | ManualEnquiry,
  b: Enquiry | ManualEnquiry,
): number => a.bookingStatus.localeCompare(b.bookingStatus);
