import React, {
  FC, KeyboardEvent, useEffect, useState,
} from 'react';
import {
  Button, Table, TablePaginationConfig, message, Input,
} from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';

import { Product } from '../../model/product';
import { Enquiry } from '../../model/enquiry';
import { ManualEnquiry } from '../../model/ManualEnquiry';
import { GetEnquiryParams, overrideEnquiry } from '../../service/dao/EnquiryDao';
import EnquiryDetails from '../EnquiryDetails/EnquiryDetails';
import LinkButton from '../LinkButton';
import { getAttachedFile } from '../../service/FileService';

import './EnquiryTable.less';

const handleSearch = (
  close: boolean,
  selectedKeys: any,
  confirm: any,
  dataIndex: string,
  setSearchText: (text: string) => void,
  setSearchedColumn: (col: string) => void,
  event?: KeyboardEvent<HTMLInputElement>,
) => {
  confirm({ closeDropdown: close });
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
  if (event) event.stopPropagation();
};

const handleReset = (clearFilters: any, setSearchText: (text: string) => void, setSearchedColumn: (col: string) => void) => {
  clearFilters();
  setSearchText('');
  setSearchedColumn('');
};

/**
 * Generate column filters for an EnquiryTable. To be used in the column definitions.
 *
 * @param title Heading of the column.
 * @param dataIndex Index of the column.
 */
export const filterColumn = (title: string, dataIndex: string) => {
  const [, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  return ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <div className="column-filter">
        <Input
          autoFocus
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          onChange={(event) => setSelectedKeys(event.target.value ? [event.target.value] : [])}
          onPressEnter={(event) => handleSearch(true, selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn, event)}
        />
        <br />
        <Button
          type="primary"
          onClick={() => handleSearch(true, selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn)}
          icon={<SearchOutlined />}
          size="small"
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters, setSearchText, setSearchedColumn)}
          size="small"
        >
          Reset
        </Button>
        <Button
          type="link"
          onClick={() => handleSearch(false, selectedKeys, confirm, dataIndex, setSearchText, setSearchedColumn)}
          size="small"
        >
          Filter
        </Button>
      </div>
    ),
    filterIcon: (<SearchOutlined />),
    filterMultiple: false,
  });
};

const displayFile = (accessToken: string, enquiryId: string, fileName: string, t: TFunction, setBusy?: Function) => {
  if (setBusy) setBusy(true);
  getAttachedFile(accessToken, enquiryId, fileName, t)
    .then((url) => {
      if (url) window.open(url, '_blank');
      else message.error(t('enquiry.table.attachment.not.found'));
      if (setBusy) setBusy(false);
    });
};

/**
 * Generate the external links for the given enquiry.
  * @param accessToken Bearer token for the user's session.
  * @param enquiry The enquiry details.
 * @param t Translation function from i18n.
 * @param translationPrefix The prefix for file type (purpose) translations.
 */
export const enquiryLinks = (accessToken: string, enquiry: Enquiry, t: TFunction, translationPrefix: string, setBusy?: Function) => {
  const links = [];
  let index = 1;

  // There's always a history link
  links.push(
    <LinkButton
      key={index}
      title={enquiry.enquiryId}
      text={t(`${translationPrefix}history`)}
      url={`${enquiry.engineUrl}${enquiry.enquiryId}`}
      maxWidth={320}
    />,
  );

  // Attached files (if present)
  if (enquiry.attachedFiles) {
    enquiry.attachedFiles.forEach((file) => {
      const consentText = t(`${translationPrefix}${file.purpose}`);
      index += 1;
      links.push(
        <>
          <br />
          <LinkButton
            key={index}
            title={enquiry.enquiryId}
            text={consentText}
            onClick={() => displayFile(accessToken, enquiry.enquiryId, file.fileName, t, setBusy)}
            maxWidth={320}
          />
        </>,
      );
    });
  }

  return links;
};

/**
 * Display a paginated table of enquiries.
 * @param columns Displayed column definitions.
 * @param getEnquiries Function to retrieve the enquiries from the server.
 * @param expandable Set to <code>true</code> if rows can be expanded to show enquiry details.
 * @param accessToken Bearer token for the user's session.
 * @constructor
 */
const EnquiryTable: FC<{
  columns: any[],
  getEnquiries: (token: string, requestParams: GetEnquiryParams) => Promise<AxiosResponse<any>>,
  expandable?: boolean,
  accessToken: string,
}> = ({
  columns,
  getEnquiries,
  expandable = false,
  accessToken,
}) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [enquiries, setEnquiries] = useState<Enquiry[]>();
  const [total, setTotal] = useState<number>();
  const [params, setParams] = useState<GetEnquiryParams>({
    page: 1, pageSize: 10, sortField: 'bookingStatusUpdated', sortOrder: 'descend',
  });
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  let showDetails = false;
  if (expandable) showDetails = true;

  const retrieveEnquiries = (token?: string) => {
    setLoading(true);
    if (token) {
      getEnquiries(token, params)
        .then((data) => {
          setEnquiries(data.data.data);
          setTotal(data.data.metadata.totalEnquiries);
          setLoading(false);
        })
        .catch(() => message.error('Error occurred while retrieving the enquiries'));
    }
  };

  useEffect(() => {
    retrieveEnquiries(accessToken);
  }, [accessToken, params]);

  const handleParamChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Enquiry> | SorterResult<Enquiry>[],
  ) => {
    const changeParams: GetEnquiryParams = {
      pageSize: pagination.pageSize || 10,
      page: pagination.current || 1,
    };
    if (!Array.isArray(sorter)) {
      changeParams.sortField = sorter.field as string;
      changeParams.sortOrder = sorter.order as string;
    }
    if (filters) {
      Object.keys(filters).forEach((column) => {
        const value = filters[column];
        if (value) {
          changeParams.filterField = column;
          changeParams.filterValue = value[0].toString();
        }
      });
    }
    setParams(changeParams);
  };

  const setEnquiryExpanded = (expanded: boolean, enquiry: Enquiry | ManualEnquiry) => {
    if (expanded) {
      setExpandedKeys([...expandedKeys, enquiry.enquiryId]);
    } else {
      setExpandedKeys(expandedKeys.filter((key) => key !== enquiry.enquiryId));
    }
  };

  const handleOverride = (enquiry: Enquiry | ManualEnquiry, products?: Product[], token?: string) => {
    setEnquiryExpanded(false, enquiry);
    if (products && token) {
      // eslint-disable-next-line no-param-reassign
      enquiry.actioned = true;
      overrideEnquiry(enquiry.enquiryId, enquiry.providerId, products, token)
        .then(
          () => {
            message.success('Override successful');
          },
          (response) => {
            message.error(`${response.toString()} ${response.data}`);
            // eslint-disable-next-line no-param-reassign
            enquiry.actioned = false;
          },
        );
    }
  };

  const getExpandable = (canExpand: boolean): object => {
    if (canExpand) {
      return {
        expandRowByClick: true,
        expandedRowKeys: expandedKeys,
        onExpand: setEnquiryExpanded,
        rowExpandable: (enquiry: Enquiry | ManualEnquiry) => !enquiry.actioned,
        // eslint-disable-next-line react/no-unstable-nested-components
        expandedRowRender: (enquiry: Enquiry | ManualEnquiry, _: any, __: any, expanded: boolean) => expanded
          && (
            <EnquiryDetails
              baseEnquiry={enquiry}
              onOverride={(products) => handleOverride(enquiry, products, accessToken)}
            />
          ),
      };
    }
    return {};
  };

  return (
    <Table
      className="enquiry-table"
      scroll={{ x: true }}
      columns={columns}
      dataSource={enquiries}
      rowKey="enquiryId"
      loading={isLoading}
      pagination={{
        pageSize: params.pageSize,
        current: params.page,
        total,
        hideOnSinglePage: true,
      }}
      onChange={handleParamChange}
      expandable={getExpandable(showDetails)}
      rowClassName={(enquiry) => (enquiry.actioned ? 'actioned' : '')}
    />
  );
};

export default EnquiryTable;
